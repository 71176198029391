<template>
  <div class="idiom-recom-item">
    <img
      @click="insertLeftText"
      class="img"
      :src="require('assets/images/idiom/add.png')"
      alt=""
    />
    <div class="idiom-recom-item-content">
      <div class="info">
        <div class="info-title">
          <span class="num">{{ info.rank }}.</span>
          <span style="cursor: pointer"
            >{{ info.association
            }}<img
              :src="require('assets/images/goldenSentence/subTitle-icon.png')"
              alt=""
              @click.stop="copyText"
          /></span>

          <!-- <div class="times">
			<img v-if="hotFlag" :src="require('assets/images/goldenSentence/hot.png')" alt=""/>
			<span v-if="hotFlag" class="date">{{info.count}}</span>
            <img v-if="timeFlag" :src="require('assets/images/goldenSentence/time.png')" alt=""/>
            <span v-if="timeFlag" class="date">{{info.favoriteTime}}</span>
			<div class="infok">信息库</div>
          </div> -->
        </div>
        <div class="info-desc">
          <img
            @click="copyText"
            :src="require('assets/images/goldenSentence/subTitle-icon.png')"
            alt=""
          />
          <span class="message">{{ info.footNotes }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cite, recommendProduction } from "api/idiom";
// import Editor from "/src/idiom-editor/Editor";
import qs from "qs";
export default {
  name: "IdiomRecomItem",
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hotFlag: false,
      timeFlag: true,
      editor: null,
    };
  },
  methods: {
    //切换信息
    update(msg) {
      if (msg == "时间") {
        this.timeFlag = true;
        this.hotFlag = false;
      }
      if (msg == "频次") {
        this.timeFlag = false;
        this.hotFlag = true;
      }
    },
    transfer(str) {
      const formats = [
        "\\[DB-OL\\]",
        "\\[N-OL\\]",
        "\\[J-OL\\]",
        "\\[M-OL\\]",
        "\\[R-OL\\]",
        "\\[D-OL\\]",
        "\\[C-OL\\]",
        "\\[G-OL\\]",
        "\\[P-OL\\]",
        "\\[S-OL\\]",
      ];
      let result = str;
      formats.forEach((f) => {
        const temp = f.replace("-", "/").replace(/\\/g, "");
        const reg = new RegExp(f);
        if (reg.test(str)) {
          result = str.replace(reg, temp);
        }
      });
      return result;
    },
    async recommendProduction(type, nodeText) {
      const params = {
        type,
        nodeText,
        nodeMd5: this.info.nodeMd5,
        textMd5: this.info.textMd5,
        labelId: this.info.labelId,
        paragraphMd5: this.info.paragraphMd5,
      };
      await recommendProduction(qs.stringify(params));
    },
    //插入
    async insertLeftText() {
      //alert(this.info.title+"======="+this.info.description);
      // this.editor.insertLabeled(this.info.association, this.info.footNotes);
      this.$emit("insetText", this.info.association);

      this.recommendProduction(1, this.info.association);
    },
    //复制内容
    async copyText() {
      let domUrl = document.createElement("input");
      domUrl.value = this.info.association;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select();
      document.execCommand("Copy");
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
      });
    },
  },
  mounted() {
    this.$bus.$on("switch", (msg) => {
      this.update(msg);
    });
    // this.editor = new Editor(".content");
  },
};
</script>

<style lang="scss" scoped>
.infok {
  min-width: 60px;
  box-sizing: border-box;
  padding: 8px 12px;
  background-color: #ff6900;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 100;
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
}
.img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  cursor: pointer;
}
.idiom-recom-item {
  @include flex-start(normal);
  &-add {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background: url("~assets/images/goldenSentence/add.png") no-repeat;
      background-size: 100%;
    }
  }
  &-content {
    @include flex-start(normal);
    flex: 1;
    margin-left: 10px;
    .num {
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }
    .info {
      flex: 1;
      margin-left: 10px;
      margin-top: -6px;
      &-title {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;

        > span {
          margin-right: 7px;
          //@include ellipsis;
          //max-width:480px;
          margin-right: 7px;
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #333;
        }
        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
          margin-left: 10px;
        }
        .times {
          //text-align:right;
          //min-width:180px;
          @include flex-start;
          > img {
            width: 15px;
            height: 15px;
            vertical-align: middle;
          }
          .date {
            min-width: 80px;
            margin-left: 5px;
            font-size: 13px;
            font-weight: 400;
            color: #999;
          }
        }
      }
      &-desc {
        margin-top: 6px;
        > img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          cursor: pointer;
        }
        .message {
          line-height: 20px;
          margin-left: 5px;
          font-size: 12px;
          font-weight: 400px;
          cursor: pointer;
          color: #999;
        }
      }
    }
  }
}
</style>
