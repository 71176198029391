<template>
  <div class="idiomhistory">
    <div
      class="back"
      @click="back"
      v-show="queryData.length != 0 || newlist.length != 0"
    >
      返回
    </div>
    <IdiomSort class="mt" @time="sortOrder" v-if="!dataFlag" />
    <div class="idiom-recom-list" v-if="!dataFlag">
      <IdiomRecomItem
        v-for="(item, index) in list"
        @insetText="insetText"
        :key="index"
        :info="{
          rank: num + index + 1,
          ...item,
        }"
      />
      <!-- <IdiomRecomItem
        v-for="(item, index) in list"
        :key="index"
        :info="{
          id: num + index + 1,
          title: item.path,
          md5: item.md5,
          advicemd5: item.advicemd5,
          tableName: item.tableName,
          sequence: item.sequence,
          description: item.adviceContent,
          favoriteTime: item.favoriteTime,
          docmd5: item.docmd5,
          count: item.count,
        }"
      /> -->
    </div>
    <div v-if="dataFlag" class="nodata">
      <img src="../../assets/images/idiom/js.png" />
      <div class="add" @click="add"></div>
      <div class="copy" @click="copyText(1)"></div>
      <div class="add-two" @click="add2"></div>
      <div class="copy-two" @click="copyText(2)"></div>

      <div class="copy-footer" @click="copyFooter"></div>
      <div class="copy-footer-two" @click="copyFooter"></div>
    </div>
    <el-pagination
      v-if="!dataFlag"
      @current-change="currentChange"
      :current-page="pageNum"
      :page-size="10"
      :pager-count="5"
      layout="total, prev, pager, next, jumper"
      :total="pageTotal"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  listCite,
  recommendHistoryPageList,
  recommendProduction,
} from "api/idiom";
import IdiomSort from "components/HistoricalReference/sort/IdiomSort";
import IdiomRecomItem from "components/HistoricalReference/recom/IdiomRecomItem";
export default {
  props: {
    queryData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      list: [],
      dataFlag: false,
      num: 0,
      newlist: [],
      //默认排序
      sort: "time",
      ascOrDesc: "desc",
      pageTotal: 0,
    };
  },
  methods: {
    //排序信息
    messSort(val) {
      this.msg = val;
    },
    //选择排序
    getsort(update) {
      this.list = update;
      if (this.list.length == 0) {
        this.dataFlag = true;
      } else {
        this.dataFlag = false;
      }
    },
    back() {
      this.$emit("hideHistory");
    },
    copyFooter() {
      let domUrl = document.createElement("input");
      domUrl.value = "深圳写手智能科技公司.成语官网writemall.com.2022-05-20";
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select();
      document.execCommand("Copy");
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
      });
    },
    insetText(text) {
      this.$emit("insetText", text);
    },
    add() {
      try {
        this.$emit("insetText", "成语～智能内容与专业服务平台");
      } catch (e) {
        console.log(e);
      }
    },
    add2() {
      try {
        this.$emit("insetText", "省事省心给力");
      } catch (e) {
        console.log(e);
      }
    },
    //复制内容
    copyText(isFlag) {
      let domUrl = document.createElement("input");
      domUrl.value =
        isFlag == 1 ? "成语～智能内容与专业服务平台" : "省事省心给力";
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select();
      document.execCommand("Copy");
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
      });
    },

    //获取引用列表
    async getList(cur) {
      let form = new FormData();
      form.append("page", this.pageNum);
      form.append("limit", 10);
      form.append("sort", this.sort);
      form.append("ascOrDesc", this.ascOrDesc);
      recommendHistoryPageList(form)
        .then((res) => {
          if (res.code == 200) {
            //是否有历史记录
            if (res.data == null) {
              this.dataFlag = true;
              this.newlist = [];
            } else {
              this.dataFlag = false;
              this.list = res.data.data;
              this.pageTotal = res.data.total;
              if (cur != 0) {
                this.num = 10 * (cur - 1);
              }
              this.pageSize = Math.ceil(res.data.total / 10);
            }
          } else {
            this.dataFlag = true;
            this.newlist = [];
          }
        })
        .catch((e) => {
          this.dataFlag = true;
        });
    },
    //当前页改变
    currentChange(cur) {
      this.pageNum = cur;
      this.getList(cur);
    },
    sortOrder(msg) {
      this.sort = msg.type;
      this.ascOrDesc = msg.sort;
      this.getList(this.pageNum);
    },
  },
  components: {
    IdiomRecomItem,
    // IdiomPager,
    IdiomSort,
  },
  created() {
    this.getList(0);
    // this.$bus.$on("time", (msg) => {
    //   this.sort = msg.type + " " + msg.sort;
    //   this.getList(this.pageNum);
    // });
  },
};
</script>

<style lang="scss" scoped>
.nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .add {
    position: absolute;
    width: 30px;
    height: 30px;
    // background-color: #000;
    top: 160px;
    left: 12px;
    cursor: pointer;
  }
  .add-two {
    position: absolute;
    width: 30px;
    height: 30px;
    // background-color: #000;
    top: 235px;
    left: 12px;
    cursor: pointer;
  }
  .copy {
    position: absolute;
    width: 30px;
    height: 30px;
    // background-color: #000;
    top: 160px;
    left: 160px;
    cursor: pointer;
  }
  .copy-footer {
    position: absolute;
    width: 30px;
    height: 30px;
    // background-color: #000;
    top: 190px;
    left: 60px;
    cursor: pointer;
  }
  .copy-footer-two {
    position: absolute;
    width: 30px;
    height: 30px;
    // background-color: #000;
    top: 280px;
    left: 60px;
    cursor: pointer;
  }
  .copy-two {
    position: absolute;
    width: 30px;
    height: 30px;
    // background-color: #000;
    top: 235px;
    left: 155px;
    cursor: pointer;
  }
  img {
    width: 522px;
    height: 324px;
  }
  .text {
    margin-top: 10px;
    color: #aaa;
  }
}

.idiomhistory {
  // @include flex-center(column);
  // justify-content: normal;
  // align-items: normal;
  // justify-content: flex-start;
  // align-items: stretch;
  position: relative;
  // min-width: 644px;
  // min-height: 550px;
  height: calc(100% - 66px);
  // height: 100vh;
  // overflow: auto;
  padding: 0 10px;
  box-sizing: border-box;

  .back {
    height: 30px;
    font-size: 14px;
    color: #999;
    line-height: 30px;
    margin-top: 10px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-left: 2px solid #999;
      border-bottom: 2px solid #999;
      transform: rotate(45deg);
    }
  }
  .el-pagination {
    text-align: center;
  }
  .idiom-sort {
    padding-left: 10px;
  }
  .idiom-recom-list {
    // overflow: hidden;
    // min-height: 680px;
    height: calc(100% - 50px);
    overflow: auto;
    padding-right: 20px;

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4588ff;
    }

    .idiom-recom-item {
      margin-top: 30px;
      padding: 0 10px;
    }
  }

  .idiom-pagination {
    padding: 20px 0;
  }
}
</style>
