<template>
  <div class="idiom-sort">
    <div
      :class="['sort-btn', item.sort != 'def' ? 'sort-btn-active' : '']"
      v-for="(item, index) in sortLang"
      :key="index"
      @click="sortMethod(item)"
    >
      <span class="sort-btn-txt">{{ item.title }}</span>
      <i
        :class="[
          'sort-icon',
          item.sort == 'asc'
            ? 'sort-icon-asc'
            : item.sort == 'desc'
            ? 'sort-icon-desc'
            : '',
        ]"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdiomSort",
  data() {
    return {
      sortLang: [
        {
          title: "引用时间",
          sort: "def",
        },
        {
          title: "引用频次",
          sort: "def",
        },
      ],
      //默认排序
      timeData: { type: "create_time", sort: "desc" },
    };
  },
  methods: {
    sortMethod(item) {
      // console.log(item);
      //时间排序
      if (item.title == "引用时间") {
        this.timeData.type = "create_time";
        if (item.sort == "def") {
          this.timeData.sort = "asc";
        }
        if (item.sort == "asc") {
          this.timeData.sort = "desc";
        }
        if (item.sort == "def") {
          this.timeData.sort = "asc";
        }
        this.$emit("time", this.timeData);
        this.$bus.$emit("switch", "时间");
      }
      //热度排序
      if (item.title == "引用频次") {
        this.timeData.type = "num";
        if (item.sort == "def") {
          this.timeData.sort = "asc";
        }
        if (item.sort == "asc") {
          this.timeData.sort = "desc";
        }
        if (item.sort == "def") {
          this.timeData.sort = "asc";
        }
        this.$emit("time", this.timeData);
        this.$bus.$emit("switch", "频次");
      }
      this.sortLang.forEach((i) => {
        if (i.title !== item.title) {
          i.sort = "def";
        }
      });
      if (item.sort == "def") {
        item.sort = "asc";
      } else if (item.sort == "asc") {
        item.sort = "desc";
      } else if (item.sort == "desc") {
        item.sort = "def";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.idiom-sort {
  // @include flex-start;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .sort-btn {
    &:first-child ~ .sort-btn {
      margin-left: 40px;
    }
    &.sort-btn-active {
      color: #ff6900;
    }
    @include flex-start;
    @include noselect;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    .sort-icon {
      position: relative;
      display: block;
      width: 9px;
      height: 13px;
      margin-left: 5px;
      background: url("~assets/images/goldenSentence/sortIcon.png") no-repeat;
      background-size: 100%;
      &.sort-icon-asc {
        background: url("~assets/images/goldenSentence/sortIcon-blue.png")
          no-repeat;
        background-size: 100%;
      }
      &.sort-icon-desc {
        background: url("~assets/images/goldenSentence/sortIcon-blue-up.png")
          no-repeat;
        background-size: 100%;
      }
      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   background: url("~assets/images/idiom/sort-def.png") no-repeat;
      //   background-size: 100%;
      // }
      // &.sort-icon-asc::before {
      //   background: url("~assets/images/idiom/sort-asc.png") no-repeat;
      //   background-size: 100%;
      // }
      // &.sort-icon-desc::before {
      //   background: url("~assets/images/idiom/sort-desc.png") no-repeat;
      //   background-size: 100%;
      // }
    }
  }
}
</style>
