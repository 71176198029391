import crewordRequest from "api/crewordRequest";
import request from "./request";
// 获取推荐列表
export function list(data) {
  return crewordRequest({
    url: "/recommend/list",
    method: "post",
    data,
  });
}

// 获取关联标准段
export function listDetail(data) {
  return crewordRequest({
    url: "/recommend/listDetail",
    method: "post",
    data,
  });
}

// 获取来源网站
// /recommend/getPlatUrl
export function getPlatUrl(data) {
  return crewordRequest({
    url: "/recommend/getPlatUrl",
    method: "post",
    data,
  });
}
//历史记录列表
export function recommendHistoryPageList(data) {
  return crewordRequest({
    url: "/recommend/recommendHistoryPageList",
    method: "post",
    data,
  });
}

// 历史引用
export function cite(data) {
  return crewordRequest({
    url: "/recommend/cite",
    method: "post",
    data,
  });
}

// 引用列表
export function listCite(data) {
  return crewordRequest({
    url: "/recommend/listCite",
    method: "post",
    data,
  });
}

//推荐兴趣列表
export function interestList() {
  return crewordRequest({
    url: "/contextual/contextualTags",
    method: "get",
  });
}

//选择兴趣标签
export function setInterest(data) {
  return crewordRequest({
    url: "/contextual/checkedContextualTags",
    method: "post",
    data,
  });
}

//推荐行为收集
export function recommendProduction(data) {
  return crewordRequest({
    url: "/recommend/recommendProductionMQ",
    method: "post",
    data,
  });
}

//查询推荐内容
export function query(data) {
  return crewordRequest({
    url: "/common/query",
    method: "post",
    data,
  });
}

export function depthLearnList(data) {
  return request({
    url: "/creation/deptlearnList",
    method: "post",
    data,
  });
}
